@zindex-link-navigation: 9000;

@import '../common.accor.com/components/header/styles.less';

// Fix header height fot cls
#ah-header-wrapper{
  z-index: 9003;
  display: block!important;

  @media only screen and (min-width: 768px) {
    height: 4.5rem;
  }

  @media only screen and (max-width:767px) {
    height: 3.8rem;
  }
}
